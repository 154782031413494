import { CONVEYANCE_TYPES } from "@koala/sdk/v4";
import Link from "next/link";
import { useRouter } from "next/router";
import { type ReactNode } from "react";
import styled from "styled-components";
import { CaretIcon } from "./icons/caret";
import { Box, Stack } from "@/components/ui";
import { LoadingPlaceholder } from "@/components/ui/loading/placeholder";
import { ROUTES } from "@/constants/routes";
import { useLocation } from "@/features/locations/service";
import { useSelector } from "@/redux";
import { selectConveyance } from "@/redux/conveyanceMode/reducer";
import { getLocationId } from "@/utils/locations";

export function Destination() {
  const { detail } = useSelector((state) => state.app.locations);
  const { location, type, address } = useSelector(selectConveyance);
  const { data, isSuccess } = useLocation(location);
  const { webConfig } = useSelector((state) => state.app.cmsConfig);
  const router = useRouter();

  if (!isSuccess) {
    return null;
  }

  let origin = "from";
  let label: string | ReactNode =
    location !== detail?.id || !router.pathname.includes(ROUTES.STORE) ? (
      <Link
        href={`/store/${getLocationId(data.data, webConfig)}/${
          data.data.label
        }`}
      >
        {data.data.label}
      </Link>
    ) : (
      data.data.label
    );
  let loading = false;

  if (type === CONVEYANCE_TYPES.DELIVERY) {
    origin = "to";
    if (address?.street_address) {
      let streetAddress = address.street_address;

      if (address.street_address_2) {
        streetAddress = `${streetAddress} ${address.street_address_2}`;
      }
      label = `${streetAddress}, ${address.city}`;
    } else {
      loading = true;
      label = "";
    }
  }

  return (
    <Stack
      gap="$1-5"
      css={{
        overflow: "hidden",
      }}
    >
      <CaretIcon />

      <Stack
        css={{
          flex: "0 1 auto",
          overflow: "hidden",
        }}
      >
        <Origin>{origin}</Origin>

        {loading ? (
          <Box
            css={{
              borderRadius: "$md",
              height: "$8",
              overflow: "hidden",
              position: "relative",
              width: "$24",
            }}
          >
            <LoadingPlaceholder />
          </Box>
        ) : (
          <Label>{label}</Label>
        )}
      </Stack>

      {!loading && <CaretIcon />}

      {type === CONVEYANCE_TYPES.DELIVERY && data?.data?.label && (
        <InlineContext>
          <ConveyanceSymbol>@</ConveyanceSymbol>
          <Label>{data.data.label}</Label>
        </InlineContext>
      )}
    </Stack>
  );
}

const ConveyanceSymbol = styled.span({
  marginRight: "4px", // Ensures enough space to avoid overlap
});

const InlineContext = styled.div({
  display: "flex",
  alignItems: "center",
});

const Origin = styled.span({
  opacity: 0.5,
});

const Label = styled.span({
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  whiteSpace: "nowrap",
});
